//import authService from '../components/api-authorization/AuthorizeService'
import { useFetchWrapper } from '../services/fetch-wrapper'

export { useSendMailService };

export const LetterOptions = {
  Multi: 'Multi',
  //Custom: 'Custom',
  //AddressWizard: 'AddressWizard',
};

export const LetterPartTypes = {
  AddressWizard: 'AddressWizard',
  Custom: 'Custom',
  RentManager: 'RentManager',
  RentManagerCustomForm: 'RentManagerCustomForm',
  Fixed: 'Fixed'
};

export const ChargeOwnerMethods = {
  None: 'None',
  FixedAmount: 'FixedAmount',
  Cost: 'Cost',
  CostAmountMarkup: 'CostAmountMarkup',
  CostPercentMarkup: 'CostPercentMarkup'
};

const useSendMailService = () => {
  const fetchWrapper = useFetchWrapper()

  const GetPropertyProspectsData = async (params) => {
    const { prospectStatuses, ...otherParams } = params
    const prospectStatusesQueryOptions = 'prospectStatuses=' + prospectStatuses.join('&prospectStatuses=')
    const queryOptions = new URLSearchParams(otherParams).toString();
  
    return fetchWrapper.get(`property/prospects/data?${queryOptions}&${prospectStatusesQueryOptions}`)
  }
  
  const GetPropertyTenantsData = async (params) => {
    const { udfOption, tenantStatuses, ...otherParams } = params
    const udfOptionQuery = 'udfOption=' + udfOption.join('&udfOption=')
    const tenantStatusesQueryOptions = 'tenantStatuses=' + tenantStatuses.join('&tenantStatuses=')
    const queryOptions = new URLSearchParams(otherParams).toString();
  
    return fetchWrapper.get(`property/tenants/data?${queryOptions}&${udfOptionQuery}&${tenantStatusesQueryOptions}`)
  }

  const GetMailingEstimates = async (params) => {
      return fetchWrapper.post(`sendmail/estimate/${params.letterOption}`, params)
  }

  const GetPreview = async (params) => {
    let classicParam = ''
    if (params.useClassic) {
      classicParam = '/classic'
    }
    return fetchWrapper.post(`sendmail/preview/${params.letterOption}${classicParam}`, params)
  }

  const DownloadPreview = async (params) => {
    return fetchWrapper.postBlob(`sendmail/downloadpreview/${params.letterOption}`, params, true)
  }
  
  const CallSendMailToRentManagerTenants = async (params) => {
    return fetchWrapper.post(`rentmanagertenantsendmail`, params)
  }

  const PollSendMailToRentManagerTenants = async (sendMailId) => {
    return fetchWrapper.get(`rentmanagertenantsendmail/${sendMailId}`)
  }

  const ValidateSendMailToRentManagerTenants = async (params) => {
    return fetchWrapper.post(`rentmanagertenantsendmail/validation`, params)
  }

  const CallSendMailToRentManagerProspects = async (params) => {
    return fetchWrapper.post(`rentmanagerprospectsendmail`, params)
  }

  const PollSendMailToRentManagerProspects = async (sendMailId) => {
    return fetchWrapper.get(`rentmanagerprospectsendmail/${sendMailId}`)
  }

  const ValidateSendMailToRentManagerProspects = async (params) => {
    return fetchWrapper.post(`rentmanagerprospectsendmail/validation`, params)
  }

  const CallSendMailToAddressWizardRecipient = async (params) => {
    return fetchWrapper.post(`addresswizardrecipientsendmail`, params)
  }

  const ValidateSendMailToAddressWizardRecipients = async (params) => {
    return fetchWrapper.post(`addresswizardrecipientsendmail/validation`, params)
  }

  const CallSendMailToCustomRecipient = async (params) => {
    return fetchWrapper.post(`customrecipientsendmail`, params)
  }

  const ValidateSendMailToCustomRecipient = async (params) => {
    return fetchWrapper.post(`customrecipientsendmail/validation`, params)
  }

  const CallSendMail = async (params) => {
    return fetchWrapper.post(`sendmail`, params)
  }
  
  const GetDetaultPaymentTypeName = async () => {
    return fetchWrapper.get(`settings/defaultpaymenttypename`)
  }

  const CallSendMailResultSupport = async (transactionLog, results) => {
    return fetchWrapper.put(`sendmail/support/${transactionLog}`, results)
  }

  const GetSanitizedLetterTemplateFields = (letterTemplateFields) => {
    const sanitizedLetterTemplateFields = {
      letterOption: letterTemplateFields.letterOption,
      letterParts: []
    }

    for (var i = 0; i < letterTemplateFields.letterParts.length; i++)
    {
      const part = letterTemplateFields.letterParts[i]

      //filter out unwanted properties, the remainder stays in "rest"
      const {fixedFiles, options, documentBlobUrl, id, label, value, ...rest} = part

      if (part.type === LetterPartTypes.Fixed || part.type === LetterPartTypes.AddressWizard || part.type === LetterPartTypes.Custom) {
        rest.fixedFileId = ''
        if (fixedFiles && fixedFiles.length > 0) {
          const file = fixedFiles[0]
          console.log("file.serverId", file.serverId)
          let str = file.serverId
          if(str.startsWith("\"") && str.endsWith("\""))
          {
              str = str.substring(1, str.length-1)
          }
          rest.fixedFileId = str
        }

        // fixedFiles.forEach(file => {
        //   console.log("file.serverId", file.serverId)
        //   let str = file.serverId
        //   if(str.startsWith("\"") && str.endsWith("\""))
        //   {
        //       str = str.substring(1, str.length-1)
        //   }
        //   rest.fixedFiles.push(str)
        // });
        
        if (part.type === LetterPartTypes.AddressWizard) {
          rest.addressWizardTemplateId = letterTemplateFields.template
        } else if (part.type === LetterPartTypes.Custom) {
          rest.isMergeFile = value.isMergeFile
        }

      } else if (part.type === LetterPartTypes.RentManager) {
        rest.letterTemplate = value
        rest.letterTemplateText = label
      } else if (part.type === LetterPartTypes.RentManagerCustomForm) {
        rest.letterTemplate = value.optionValue
        rest.letterTemplateText = value.optionLabel
        console.log('value', value)
        console.log('value.dateSelectionRange', value.dateSelectionRange)
        console.log('value.dateSelectionRange[0]', value.dateSelectionRange[0].startDate)
        console.log('value.dateSelectionRange[0]', value.dateSelectionRange[0].endDate)
        
        console.log('value.commentValue', value.commentValue)
        
        rest.startDate = new Date(value.dateSelectionRange[0].startDate).toISOString()
        rest.endDate = new Date(value.dateSelectionRange[0].endDate).toISOString()
        rest.comment = value.commentValue
        rest.showUnpostedPerDayLateFeesToDay = value.showUnpostedPerDayLateFeesToDay
        rest.showDailyInterestLoans = value.showDailyInterestLoans
        rest.showMeterReadingsAndUsage = value.showMeterReadingsAndUsage
        rest.showCalculationDetailsMUPlus = value.showCalculationDetailsMUPlus
      }

      sanitizedLetterTemplateFields.letterParts.push({
        ...rest
      })
    }

    // letterTemplateFields.letterParts.forEach((part) => {
    //   //filter out unwanted properties, the remainder stays in "rest"
    //   const {fixedFiles, options, documentBlobUrl, id, label, value, ...rest} = part

    //   if (part.type === LetterPartTypes.Fixed) {
    //     rest.fixedFileId = ''
    //     if (fixedFiles && fixedFiles.length > 0) {
    //       const file = fixedFiles[0]
    //       console.log("file.serverId", file.serverId)
    //       let str = file.serverId
    //       if(str.startsWith("\"") && str.endsWith("\""))
    //       {
    //           str = str.substring(1, str.length-1)
    //       }
    //       rest.fixedFileId = str
    //     }

    //     // fixedFiles.forEach(file => {
    //     //   console.log("file.serverId", file.serverId)
    //     //   let str = file.serverId
    //     //   if(str.startsWith("\"") && str.endsWith("\""))
    //     //   {
    //     //       str = str.substring(1, str.length-1)
    //     //   }
    //     //   rest.fixedFiles.push(str)
    //     // });
    //   } else if (part.type === LetterPartTypes.RentManager) {
    //     rest.letterTemplate = value
    //     rest.letterTemplateText = label
    //   }

    //   sanitizedLetterTemplateFields.letterParts.push({
    //     ...rest
    //   })
    // })

    console.log('sanitizedLetterTemplateFields', sanitizedLetterTemplateFields)

    return sanitizedLetterTemplateFields
  }


  return { GetPropertyTenantsData,
    GetPropertyProspectsData,
    GetSanitizedLetterTemplateFields,
    GetMailingEstimates,
    GetPreview,
    DownloadPreview,
    CallSendMailToAddressWizardRecipient,
    ValidateSendMailToAddressWizardRecipients,
    CallSendMailToCustomRecipient,
    ValidateSendMailToCustomRecipient,
    CallSendMailToRentManagerTenants,
    ValidateSendMailToRentManagerTenants,
    PollSendMailToRentManagerTenants,
    CallSendMailToRentManagerProspects,
    PollSendMailToRentManagerProspects,
    ValidateSendMailToRentManagerProspects,
    CallSendMail,
    CallSendMailResultSupport,
    GetDetaultPaymentTypeName }
}