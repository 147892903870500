import React, { useCallback, useEffect, useRef, useState } from 'react'
import { pdfjs, Document, Page } from "react-pdf";
import { VariableSizeList as List } from "react-window";
import { BinocularsFill, Download, Printer } from 'react-bootstrap-icons'
import { Button, Col, Modal, ModalBody, Row, Spinner, UncontrolledTooltip } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { LetterPartTypes, useSendMailService } from '../../../services/SendMailService'
import { useWindowSize } from 'usehooks-ts'
import { PDFDocument } from "pdf-lib";
import printJS from 'print-js'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageRow = ({ index, style, scale, outerRef }) => {
  /*const onPageRenderSuccess = (page) => {
    console.log(`Page ${page.pageNumber} rendered`);
  }*/

  return (
    <div style={style} ref={outerRef}>
      <Page /*onRenderSuccess={onPageRenderSuccess}*/ pageIndex={index} scale={scale} />
    </div>
  );
}

const PreviewModal = ({onClose, showModal, request: previewRequest}) => {

  const modalRef = useRef(null)
  const modalHeaderRef = useRef(null)
  const listRef = useRef(null)
  const elementRef = useRef(null)
  const scrollableDiv = useRef(null)
  //const [showPreview] = useState(true)
  const numPages = useRef(5)
  const pageRefs = useRef([])
  const nextClickedRef = useRef(false)
  const [previewLimit, setPreviewLimit] = useState(5)
  const [scale, setScale] = useState(1)
  const [previewOffset, setPreviewOffset] = useState(-1)
    //const [previewRequest, setPreviewRequest] = useState({})
  const [previewRequestBreakdown, setPreviewRequestBreakdown] = useState([])
  const [preferredWidth, setPreferredWidth] = useState(640)
  const [preferredHeight, setPreferredHeight] = useState(400)
  const [pdf, setPdf] = useState(null);
  const [pageViewports, setPageViewports] = useState(null);
  const [currentScrollTop, setCurrentScrollTop] = useState(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [loadingNextDoc, setLoadingNextDoc] = useState(false)
  const MySwal = withReactContent(Swal)

  const [pdfByteArray, setPdfByteArray] = useState(null)
  const [pdfByteArrayBuffer, setPdfByteArrayBuffer] = useState(null)

  const sendMailService = useSendMailService()
  const { height : windowHeight = 0 } = useWindowSize()

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const onBeforeUnload = useCallback(e => {
      //old browsers
      if (e) {e.returnValue = 'Sure?';}
      //safari, chrome(chrome ignores text)
      return 'Sure?';
  }, [])

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload, true)
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload, true)
    }
  }, [])

  // useUnmount(() => {
  //   // Cleanup logic here
  // })

  const showError = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'error',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const togglePreview = useCallback(() => {
    onClose()
  }, [onClose])

  const showPreviewError = useCallback((previewResult) => {
    //if (!(previewResult instanceof Blob)) {
      if (!(previewResult instanceof ArrayBuffer)) {
      if (!previewResult || previewResult.returnCode !== 0) {
        console.log(previewResult)
        showError("Error Generating Preview", previewResult && previewResult.errorMessage)
        
        return true
      }
    }
    
    return false
  }, [])

  const updatePdfByteArray = useCallback((uInt8Array) => {
    setPdfByteArray(uInt8Array)
  }, [setPdfByteArray])

  const downloadDocument = useCallback((e) => {
    e.currentTarget.blur()

    if (previewRequestBreakdown.length !== 0) {

      const hasMore = previewOffset < previewRequestBreakdown.length - 1
      if (hasMore) {
        showError("Preview All Letters First", "Download is not currently available. You must scroll to the bottom of all letters first before clicking download again.")
      } else {
        //get the file name by using best option in letter options
        let rentManagerTemplateTitle = null
        let fixedFileTitle = null
        previewRequest.letterParts.forEach(part =>
        {
            if (fixedFileTitle == null && (part.Type == LetterPartTypes.Fixed || part.Type == LetterPartTypes.AddressWizard || part.Type == LetterPartTypes.Custom))
            {
                fixedFileTitle = part.letterTemplateText
            }
            else if (rentManagerTemplateTitle == null && (part.Type != LetterPartTypes.Fixed || part.Type == LetterPartTypes.AddressWizard || part.Type == LetterPartTypes.Custom))
            {
                rentManagerTemplateTitle = part.letterTemplateText
            }
        })
  
        //prioritize a rent manager template over a file name.
        //but when it's a static file, then the only option is to use the file name.
        const filename = rentManagerTemplateTitle ?? fixedFileTitle
  
        const blob = new Blob([pdfByteArray.data], { type: "application/pdf;charset=utf-8" });
        const a = document.createElement("a");
        const blobUrl = URL.createObjectURL(blob);
        a.class = "d-none";
        a.href = blobUrl;
        a.download = filename + ' (Preview).pdf';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blobUrl);
        setTimeout(() => {
          document.body.removeChild(a); // On modern browsers you can use `tempLink.remove();`
        }, 100);
      }
    }
  }, [previewRequest, pdfByteArray, previewOffset, previewRequestBreakdown])

  const printDocument = useCallback((e) => {
    e.currentTarget.blur()
    
    if (previewRequestBreakdown.length !== 0) {

      const hasMore = previewOffset < previewRequestBreakdown.length - 1
      if (hasMore) {
        showError("Preview All Letters First", "Printing is not currently available. You must scroll to the bottom of all letters first before clicking print again.")
      } else {
        const blob = new Blob([pdfByteArray.data], { type: "application/pdf;charset=utf-8" })
        const blobUrl = URL.createObjectURL(blob)
        //window.print(blobUrl)
        printJS(blobUrl)
        URL.revokeObjectURL(blobUrl);
      }
    }
  }, [previewRequest, pdfByteArray, previewOffset, previewRequestBreakdown])

  const loadNextPreview = useCallback(async () => {
    console.log('previewRequest', previewRequest)

    if (previewRequestBreakdown.length !== 0) {

      const hasMore = previewOffset < previewRequestBreakdown.length - 1

      if (previewOffset == 0) {
        const promises = []
        
        promises.push(sendMailService.DownloadPreview({
          ...previewRequest,
          recipients: previewRequestBreakdown[previewOffset]
        }).catch(error => { PlainSwal.close(); showPreviewError(error); }))

        if (hasMore) {
          promises.push(sendMailService.DownloadPreview({
            ...previewRequest,
            recipients: previewRequestBreakdown[previewOffset + 1]
          }).catch(error => { PlainSwal.close(); showPreviewError(error); }))
        }

        const promisesResponse = await Promise.all(promises)
        if (!showPreviewError(promisesResponse[0]) && ((hasMore && !showPreviewError(promisesResponse[1])) || !hasMore)) {
          //console.log('First Alles Gut Ya!')
          
          if (hasMore) {
            const uint8ArrayBuffer = new Uint8Array(promisesResponse[1]);
            setPdfByteArrayBuffer({data: uint8ArrayBuffer})
          }

          const uint8Array = new Uint8Array(promisesResponse[0]);
          updatePdfByteArray({data: uint8Array})
        }
      } else {
        //merge new pdf to bottom of current pdf
        //const uint8Array = new Uint8Array(pdfByteArrayBuffer); //load from the buffer

        const mergedPdf = await PDFDocument.create();
        const pdfA = await PDFDocument.load(pdfByteArray.data);
        const pdfB = await PDFDocument.load(pdfByteArrayBuffer.data);
  
        const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
        copiedPagesA.forEach((page) => mergedPdf.addPage(page));
  
        const copiedPagesB = await mergedPdf.copyPages(pdfB, pdfB.getPageIndices());
        copiedPagesB.forEach((page) => mergedPdf.addPage(page));
  
        const mergedUint8Array = await mergedPdf.save();
        
        updatePdfByteArray({data: mergedUint8Array})
        setLoadingNextDoc(false)

        if (hasMore) {
          const nextBuffer = await sendMailService.DownloadPreview({
            ...previewRequest,
            recipients: previewRequestBreakdown[previewOffset + 1]
          }).catch(error => { PlainSwal.close(); showPreviewError(error); })

          if (!showPreviewError(nextBuffer)) {
            const uint8ArrayBuffer = new Uint8Array(nextBuffer);
            setPdfByteArrayBuffer({data: uint8ArrayBuffer})
          }
        }
      }
    }
  }, [scrollableDiv, previewRequest, previewRequestBreakdown, previewOffset, updatePdfByteArray, setPdfByteArrayBuffer, setLoadingNextDoc])


  /**
   * React-Window cannot get item size using async getter, therefore we need to
   * calculate them ahead of time.
   */
  useEffect(() => {
    console.log('PDF HAS CHANGED!!!')

    setPageViewports(null);

    if (!pdf) {
      return;
    }

    console.log('New PDF Num pages:' + pdf.numPages);

    if (numPages && numPages.current) {
      numPages.current = pdf.numPages

      pageRefs.current = Array.from(new Array(pdf.numPages - 1)).map(
        (_, i) => pageRefs.current[i] ?? React.createRef()
      );
    }

    (async () => {
      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      // const nextPageViewports = await asyncMap(pageNumbers, (pageNumber) =>
      //   pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: 1 }))
      // );

      const nextPageViewports = await Promise.all(pageNumbers.map(async (pageNumber) => {
        return pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: scale }))
      }));

      setPageViewports(nextPageViewports);

      if (scrollableDiv && scrollableDiv.current) {
        const el = scrollableDiv.current
        //console.log('Setting height of', el.firstChild)
        //console.log('to', el.scrollHeight)
        el.firstChild.style.height = '' + elementRef.current.scrollHeight + 'px'
        //el.firstChild.style.height = elementRef.current.style.height

        if (elementRef && elementRef.current) {
          el.style.height = elementRef.current.style.height
        }

        //console.log('Setting scrollableDiv isloading', false)
        el.dataset.isloading = false

        //console.log('supposed to scroll to last scrolle top:', el.dataset.currentScrollTop)
        if (listRef?.current) {
          //console.log('und I did!', el.dataset.currentScrollTop)
          listRef.current.scrollTo(el.dataset.currentScrollTop)
        }
      }
    })();
  }, [pdf, listRef, scale, numPages, pageRefs]);

  function onDocumentLoadSuccess(nextPdf) {
    //console.log('onDocumentLoadSuccess!!!!!!!!!!!!!!')
    setPdf(nextPdf);
  }

  function getPageHeight(pageIndex, estimate = false) {
    if (!pageViewports) {
      throw new Error("getPageHeight() called too early");
    }

    let pageViewport = pageViewports[0]
    if (pageIndex < pageViewports.length) {
      if (!estimate) {
        pageViewport = pageViewports[pageIndex];
      } else {
        let shortestViewport = pageViewports[0]
        for (var i = 0; i < Math.min(pageViewports.length, pageViewports.length / previewLimit); i++) {
          if (pageViewports[i].height < shortestViewport.height) {
            shortestViewport = pageViewports[i]
          }
        }
        pageViewport = shortestViewport
      }
    }

    return pageViewport.height;
  }

  // function getPageWidth(pageIndex) {
  //   console.log('getPageWidth called', pageIndex)
  //   if (!pageViewports) {
  //     throw new Error("getPageWidth() called too early");
  //   }

  //   let pageViewport = pageViewports[0]
  //   if (pageIndex < pageViewports.length) {
  //     pageViewport = pageViewports[pageIndex];
  //   }

  //   return pageViewport.width;
  // }

  const scrollingPdf = useCallback((event) => {
    //console.log(event)
    if (!elementRef.current) {
      return;
    }

    //const el = elementRef.current.parentElement?.parentElement
    const el = scrollableDiv.current

    if (!el) {
      return
    }

    //listRef.current.scrollTo(scrollableDiv.current.scrollTop)

    if (el.scrollTop + el.offsetHeight >= el.scrollHeight) {
      console.log('the end!')
      setLoadingNextDoc(false)
    }

    //console.log('el.scrollTop', el.scrollTop, el.dataset.currentScrollTop)
    if (el.scrollTop < el.dataset.currentScrollTop) {
      console.log('going up!')
      //return
    }

    const factor = 0.75
    /*if (numPages = previewLimit * 1) {
      
    }*/
    //const heightPerPage = el.scrollHeight / numPages.current;
    //const pagesPerRecipient = numPages.current / previewRequest.recipients.length
    //const pagesPerLetter = numPages.current / previewLimit
    if (!el.dataset.loadNextBatchAt) {
      el.dataset.loadNextBatchAt = Math.floor((el.scrollHeight / previewLimit) * 3)
    }
    
    //console.log('el.scrollHeight: ', el.scrollHeight)
    //console.log('numPages.current: ', numPages.current)
    //console.log('pagesPerLetter: ', pagesPerLetter)
    //console.log('heightPerLetter: ', heightPerLetter)

    //const medianPerLimit = previewLimit / 2
    //const breakAt = (el.scrollHeight / 2) + (heightPerPageAvg * medianPerLimit * 0.75); // el.scrollTop + el.offsetHeight
    //const breakAt = el.scrollHeight - (heightPerLetter * 1.75)
    const breakAt = el.scrollHeight - parseInt(el.dataset.loadNextBatchAt)

    //console.log('breakAt: ', breakAt)
    if ((el.scrollTop + el.offsetHeight >= breakAt /*el.scrollHeight * 0.75*/) && (el.scrollTop > el.dataset.currentScrollTop)) {
      if (el.scrollTop + 20 > el.dataset.currentScrollTop) {
        //console.log('time to load!')
        setLoadingNextDoc(true)
      }
    }

    if (el.dataset.currentScrollTop > el.scrollTop + 20 || el.dataset.currentScrollTop < el.scrollTop - 20) {
      setCurrentScrollTop(el.scrollTop)
      el.dataset.currentScrollTop = el.scrollTop
    }
  }, [])

  const increasePreviewOffset = useCallback(() => {
    //console.log('increasePreviewOffset state: ', previewOffset, previewRequestBreakdown.length)
    if (previewOffset < previewRequestBreakdown.length - 1) {
      //console.log('increasing previewOffset to', (previewOffset + 1))
      setPreviewOffset(previewOffset + 1)

      const el = scrollableDiv.current
      if (el) {
        el.dataset.isloading = true
      }
    } else {
      setLoadingNextDoc(false)
    }
  }, [scrollableDiv, previewOffset, previewRequestBreakdown, setPreviewOffset, setLoadingNextDoc])

  const displayCurrentPage = useCallback(({
    //overscanStartIndex,
    //overscanStopIndex,
    visibleStartIndex,
    visibleStopIndex
  }) => {
    //console.log('visible Index start to end', visibleStartIndex, visibleStopIndex, overscanStartIndex, overscanStopIndex)
    setCurrentPageIndex([visibleStartIndex, visibleStopIndex])
  }, [scrollableDiv, setCurrentPageIndex])

  useEffect(() => {
    //console.log('loadingNextDoc?', loadingNextDoc)
    const el = scrollableDiv.current
    if (el) {
      //console.log('el.dataset.isloading?', el.dataset.isloading)
      if (el.dataset.isloading.toString() === 'false')
      {
        //console.log('checking static loading flag?', el.dataset.isloading)
        if (loadingNextDoc) {
          //console.log('calling inscrease preview offset')
          increasePreviewOffset();
        }
      }
      else {
        //console.log('scrollableDiv.current says it\'s already loading')  
      }
    } else {
      //console.log('scrollableDiv.current doesn\'t exists')
    }
  }, [scrollableDiv, loadingNextDoc, increasePreviewOffset])

  useEffect(() => {
    if (Object.keys(previewRequest).length !== 0) {
      console.log('We\'ve got request!', previewRequest)
      let breakdown = []
      if (previewRequest.recipients && previewRequest.recipients.length !== 0) {
        breakdown = previewRequest.recipients.reduce((all,one,i) => {
          const ch = Math.floor(i/previewLimit);
          all[ch] = [].concat((all[ch]||[]),one);
          return all
        }, [])
      } else {
        breakdown = [[0]]
      }

      console.log('SET setPreviewRequestBreakdown')
      console.log('old recipients', previewRequest.recipients)
      setPreviewRequestBreakdown(breakdown)
      setPreviewOffset(0)
    }
  }, [previewRequest, previewLimit, setPreviewRequestBreakdown, setPreviewOffset])

  useEffect(() => {
    //console.log('previewOffset', previewOffset)
    if (previewOffset !== -1) {
      //console.log('calling loadNextPreview')
      loadNextPreview()
    }
  }, [previewOffset, loadNextPreview])

  useEffect(() => {
    if (modalRef && modalRef.current) {
      let nonContentHeight = 0
      nonContentHeight += getElementHeightIfExists('.modal-header')
      nonContentHeight += getElementHeightIfExists('.modal-footer')
      const pHeight = windowHeight - nonContentHeight
      setPreferredHeight(pHeight)
      setPreferredWidth(pHeight * 1.5)
    }
  }, [modalRef, modalHeaderRef, windowHeight, setPreferredHeight, setPreferredWidth])

  useEffect(() => {
    if (scrollableDiv && scrollableDiv.current)
    {
      const el = scrollableDiv.current
      //console.log('supposed to scroll to last scrolle top:', el.dataset.currentScrollTop)
      if (el) {
        if (listRef?.current) {
          //console.log('und I did!', el.dataset.currentScrollTop)
          listRef.current.scrollTo(el.dataset.currentScrollTop)
        }
      }
    }
    
    //if (listRef?.current) listRef.current.scrollTo(currentScrollTop)
  }, [currentScrollTop, listRef])

  useEffect(() => {
    
    if (scrollableDiv && scrollableDiv.current) {
      //console.log('COUCOU!!!')

      const el = scrollableDiv.current
      el.dataset.currentScrollTop = 0
      //console.log(elementRef.current.parentElement.parentElement)
      el.addEventListener("scroll", scrollingPdf, true)
      return () => {
        if (el) {
          el.removeEventListener("scroll", scrollingPdf, true)
        }
      }
    }
  }, [scrollableDiv.current, scrollingPdf])

  const getElementHeightIfExists = useCallback((querySelector) => {
    const element = modalRef.current.querySelector(querySelector)
    let height = 0
    if (element) {
      height = element.offsetHeight
    }
    return height
  }, [modalRef])

  const zoom = useCallback((zoomIn) => {
    const tick = 0.25
    if (zoomIn) {
      setScale(scale + tick)
    } else {
      setScale(scale - tick)
    }
  }, [scale, setScale])

  const pageMove = useCallback((tick) => {
    if (pageRefs && pageRefs.current) {
      //console.log('current Page Refs: ', pageRefs)
      //console.log('currentPageIndex: ', currentPageIndex)
      let cpIndex = currentPageIndex[0] //start of visible range
    
      if (pageRefs && pageRefs.current)
      {
        //const el = scrollableDiv.current
        //console.log('scrollableDiv.current.scrollHeight:', scrollableDiv.current.scrollHeight)
        //console.log('scrollableDiv.current.scrollTop:', scrollableDiv.current.scrollTop)
        
        if (cpIndex + tick < pageRefs.current.length && cpIndex + tick >= 0) {
         
          if (pageRefs.current[cpIndex + tick] && pageRefs.current[cpIndex + tick].current) {
            
            if (tick < 0) {
              let height = 0;
              for (var i = 0; i < cpIndex; i++) {
                height += pageViewports[i].height
              }
              //console.log('pageViewports total:', height)
              //console.log('scrollableDiv.current.scrollTop:', scrollableDiv.current.scrollTop)
              //console.log('pageViewports[cpIndex].height:', pageViewports[cpIndex].height)
              //console.log('scrollableDiv.current.scrollTop - pageViewports[cpIndex].height:', scrollableDiv.current.scrollTop - pageViewports[cpIndex].height)

              const scrollHeight = Math.ceil(scrollableDiv.current.scrollTop - pageViewports[cpIndex].height)
              const quarterLastPageHeight = Math.ceil(pageViewports[cpIndex].height / 3)

              if (scrollHeight + quarterLastPageHeight < height) {
                height -= pageViewports[cpIndex - 1].height
              }

              scrollableDiv.current.scroll({ top: height, behavior: "smooth" });

            }
            else {
              let height = 0;
              for (var i = 0; i <= cpIndex; i++) {
                height += pageViewports[i].height
              }
              //console.log('pageViewports total:', height)

              const scrollHeight = Math.ceil(scrollableDiv.current.scrollTop + pageViewports[cpIndex].height)
              const halfLastPageHeight = Math.ceil(pageViewports[cpIndex].height / 2)
              if (scrollHeight - halfLastPageHeight > height) {
                //if (currentPageIndex[0] !== currentPageIndex[1]) {
                  height += pageViewports[cpIndex + 1].height
                //}
              }

              scrollableDiv.current.scroll({ top: height, behavior: "smooth" });
            }

            /*if (pageRefs.current[cpIndex + tick + 1]
              && pageRefs.current[cpIndex + tick + 1].current
              && currentPageIndex[0] !== currentPageIndex[1]
              && cpIndex + tick === currentPageIndex[1]) {
              pageRefs.current[cpIndex + tick + 1].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else {
              pageRefs.current[cpIndex + tick].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }*/
            

            //if (cpIndex !== 0 && currentPageIndex[0] !== currentPageIndex[1]) {
              //////var topOfElement = pageRefs.current[cpIndex + tick].current.offsetTop + 1 // + modalHeaderRef.clientHeight //+ headerHeight //modalHeaderRef.current.height;
              //console.log('topOfElement:', topOfElement)
              //console.log('modalHeaderRef.current.height:', modalHeaderRef.current.height)
              
              //////scrollableDiv.current.scroll({ top: topOfElement, behavior: "smooth" });
            //} else {
              //pageRefs.current[cpIndex + tick].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            //}

          }
        } else if (currentPageIndex[0] !== currentPageIndex[1]) {
          if (pageRefs.current[cpIndex] && pageRefs.current[cpIndex].current) {
            pageRefs.current[cpIndex].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            //var topOfElement = pageRefs.current[cpIndex].current.offsetTop - modalHeaderRef.current.height;
            //scrollableDiv.current.scroll({ top: topOfElement, behavior: "smooth" });
          }
        }
      }
    }
  }, [elementRef, modalHeaderRef, nextClickedRef, scrollableDiv, currentPageIndex, pageRefs, pageViewports])

  const renderRow = useCallback((props) => {
    //setCurrentPage(props.index + 1)
    //console.log(props.index, pageRefs.current[props.index])
    if (!pageRefs.current[props.index]) {
      pageRefs.current[props.index] = React.createRef()
    }

    return <PageRow {...props} scale={scale} outerRef={pageRefs.current[props.index]} />
  }, [scale/*, setCurrentPage*/])

  return (
    <div>
      <Modal innerRef={modalRef} isOpen={showModal} keyboard={false} toggle={togglePreview} fullscreen>
        <div ref={modalHeaderRef} className='text-primary bg-light modal-header pt-1 pb-1'>
          <div className='d-flex flex-fill align-items-center justify-content-center'>
            <Row className='w-100'>
              <Col xs={12} md={1} xl={2} xxl={3}><h5 className='modal-title'><BinocularsFill size={28} /> <span className='text-white'>Preview</span></h5></Col>
              <Col xs={12} md={10} xl={8} xxl={6} className='text-center'>
                {pdfByteArray && (
                  <Row>
                    <Col xs={12} md={6}>
                      <Button style={{width: '9.5rem'}} onClick={() => zoom(false)}>Zoom Out</Button>
                      {' '}
                      <Button style={{width: '9.5rem'}} onClick={() => zoom(true)}>Zoom In</Button>
                    </Col>
                    <Col xs={12} md={6}>
                      <Button style={{width: '9.5rem'}} onClick={() => pageMove(-1)}>Previous Page</Button>
                      {' '}
                      <Button style={{width: '9.5rem'}} onClick={() => pageMove(+1)}>Next page</Button>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xs={12} md={1} xl={2} xxl={3}></Col>
            </Row>
            <button type="button" className="btn btn-white-outline btn-preview-menu p-0 m-0 me-4 mb-1" aria-label="Download" onClick={downloadDocument} id="downloadDocumentBtn"><Download  size={19} /></button>
            <UncontrolledTooltip delay={{ "show": 500, "hide": 0 }} placement="left" target="downloadDocumentBtn">Download</UncontrolledTooltip>
            <button type="button" className="btn btn-white-outline btn-preview-menu p-0 m-0 me-4 mb-1" aria-label="Print" onClick={printDocument} id="printDocumentBtn"><Printer  size={19} /></button>
            <UncontrolledTooltip delay={{ "show": 500, "hide": 0 }} placement="left" target="printDocumentBtn">Print</UncontrolledTooltip>
            <button type="button" className="btn-close p-0 m-0" aria-label="Close" onClick={togglePreview} id="closeDocumentBtn"></button>
            <UncontrolledTooltip delay={{ "show": 500, "hide": 0 }} placement="left" target="closeDocumentBtn">Close</UncontrolledTooltip>
          </div>
          
        </div>
        
        <ModalBody className='ps-0 pt-0 pb-0 pe-0 overflow-hidden bg-black'>
          {pdfByteArray ? (
            <div ref={scrollableDiv} id="scrollableDiv" style={{ height: 300, overflow: "auto", margin: 'auto' }}>
              <div className="Example__container">
                <div className="Example__container__document preview__container__document">
                  <Document
                    file={pdfByteArray}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {pdf && pageViewports ? (
                      <List
                        ref={listRef}
                        outerRef={elementRef}
                        className="react-pdf-scroll-override"
                        //width={windowWidth}
                        //height={windowHeight}
                        //width={((scrollableDiv?.current.clientWidth - preferredWidth))}
                        //width={getPageWidth(0)}
                        //width={scrollableDiv?.current.clientWidth}
                        width={`${612 * scale}px`}
                        //width={getPageWidth(0)}
                        height={preferredHeight}
                        estimatedItemSize={getPageHeight(0, true)}
                        itemCount={pdf.numPages}
                        itemSize={getPageHeight}
                        onItemsRendered={displayCurrentPage}
                      >
                        {renderRow}
                      </List>
                    ) : null}
                  </Document>
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex flex-row align-items-center justify-content-center pe-4 text-white' style={{ height: 300 }}><Spinner /><span className='ps-2'>Loading...</span></div>
          )}
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={togglePreview}>
            Close
          </Button>
        </ModalFooter> */}
      </Modal>
    </div>
  )
}

export default PreviewModal